import React from 'react';
import InputBase from '@mui/material/InputBase';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import IconButton from '@mui/material/IconButton';
import {Link} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { search } from '../../store.js';
import './footer.sass';

function Footer() {
    const navigate = useNavigate()
    const find = () => {
        search.run()
        navigate('/')
    }
    return (
        <footer className="footer">
            <div className="footer__logo-search-wrapper">
                <a href='/'>
                    <h2 className="site-logo">ФОРУМ</h2>
                </a>
                <span className="spacer"></span>

                <a href="https://map.ncpti.ru/" className='map-link roboto'>
                Интерактивная карта
                </a>
            </div>

            <div className='footer__terms-wrapper'>
                <a href='https://ncpti.su/wp-content/uploads/2022/05/politika-konfidenczialnosti.docx' className="footer__term-link">
                    <span className="footer__term roboto">Политика конфиденциальности</span>
                </a>
                <br />
                <a href='https://ncpti.su/wp-content/uploads/2022/05/soglasie-na-obrabotku-personalnyh-dannyh.docx' className="footer__term-link">
                    <span className="footer__term roboto">Соглашение на обработку персональных данных</span>
                </a>
            </div>
        </footer>)
}

export default Footer;