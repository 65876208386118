import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import Info from "../common/Info/Info";
import useAuth from "../../hooks/useAuth";
import { questionPage } from "../../store.js";
import api from "../../services/api";
import { observer } from "mobx-react-lite";
import TextField from "@mui/material/TextField";

function AnswersList({ reply_threads, user_id, report, handleCommentCreate }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const auth = useAuth();
  const [hasDefinedAnswer, setHasDefinedAnswer] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState("none");
  const selectCorrectAnswer = (id) => {
    setHasDefinedAnswer(true);
    setSelectedAnswer(id);
  };
  useEffect(() => {
    reply_threads.map((thread) => {
      if (thread.correct_answer) {
        setHasDefinedAnswer(true);
      }
      questionPage.addUser(thread.user_id.id);
    });
  }, []);
  return reply_threads.map((thread, id) => {
    return (
      <section key={thread.id} className="answers inner-padding">
        {(() => {
          if (
            !hasDefinedAnswer &&
            !thread.correct_answer &&
            auth &&
            auth.user &&
            auth.user.id == user_id &&
            selectedAnswer === "none"
          ) {
            return (
              <IconButton
                className="correct-answer-btn roboto"
                onClick={() => (
                  selectCorrectAnswer(id), api.defineAnswer.check(thread.id)
                )}
              >
                <CheckIcon />
                отметить ответ как правильный
              </IconButton>
            );
          }
        })()}

        <h3 className="answers-count">
          {id + 1} ответ{" "}
          {(() => {
            if (thread.correct_answer || id == selectedAnswer) {
              return <CheckIcon color="primary" />;
            }
          })()}
        </h3>
        <div className="answer">
          <div className="answer-body">
            <div className="answer__meta">
              <svg
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiAvatar-fallback css-13y7ul3 avatar"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="PersonIcon"
                style={{ minWidth: "40px", minHeight: "40px" }}
              >
                <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
              </svg>
              <span className="answer__author">
                {questionPage.users[thread.user_id.id]}
              </span>
              <span className="spacer"></span>
              <Info type="reply_thread" id={thread.id} />
            </div>
            <p className="answer__description">{thread.content}</p>
          </div>
        </div>

        <ul
          className={`replies show-${
            thread.sub_replies.length ? "block" : "none"
          }`}
        >
          {thread.sub_replies.map((replies) => (
            <li key={replies.id} className="answer">
              <div className="answer-body">
                <div className="answer__meta">
                  <svg
                    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiAvatar-fallback css-13y7ul3 avatar"
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="PersonIcon"
                    style={{ minWidth: "40px", minHeight: "40px" }}
                  >
                    <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
                  </svg>
                  <span className="answer__author roboto">
                    {questionPage.users[replies.user_id]}
                  </span>
                  <span className="spacer"></span>
                  <Info type="thread_message" id={replies.id} />
                </div>
                <div className="answer__description roboto">
                  {replies.content}
                </div>
              </div>
            </li>
          ))}
        </ul>

        <CommentCreate
          onSubmit={(value) =>
            handleCommentCreate({ thread_id: thread.id, content: value })
          }
        />
      </section>
    );
  });
}

const CommentCreate = ({ onSubmit }) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);

  const onClick = () => {
    if (value) {
      onSubmit(value);
      setValue("");
      setError(false)
    } else {
      setError(true);
    }
  };

  return (
    <div className="comment-answer-form">
      <TextField
        className="comment-answer-input"
        multiline
        minRows={2}
        maxRows={6}
        value={value}
        placeholder="Написать комментарий"
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      {error ? <span className="error">Пожалуйста, заполните это поле</span> : <></>}
      <button onClick={onClick} className="send-comment-button">
        Отправить
      </button>
    </div>
  );
};

export default observer(AnswersList);
