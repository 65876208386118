import React from "react";
import "./App.sass";
import './styles/common/validation.sass'
import { Route, Routes, Navigate } from "react-router-dom";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import Main from "./components/main/Main";
import Login from "./components/login/Login";
import QuestionPage from "./components/questionPage/QuestionPage";
import UserPage from "./components/userPage/UserPage";
import CreateQuestion from "./components/common/CreateQuestion/CreateQuestion";
import NotFound from "./components/NotFound";
import useAuth from "./hooks/useAuth";
import PrivateRoute from "./components/common/PrivateRoute";
// import GuestRoute from "./components/common/GuestRoute";
import { CircularProgress, Container, Grid } from "@mui/material";
import { topics } from "./store";

function App() {
  const auth = useAuth();
  React.useEffect(() => {
    topics.get()
  },[auth])
  return auth.isLoaded ? (
    <div className="site-container">
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Main />
            </PrivateRoute>
          }
        />
        <Route
          path="/login"
          element={
            <Login />
          }
        />
        <Route
          path="/ask"
          element={
            <PrivateRoute>
              <CreateQuestion />
            </PrivateRoute>
          }
        />
        <Route
          path="/question/:id"
          element={
            <PrivateRoute>
              <QuestionPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/:id"
          element={
            <PrivateRoute>
              <UserPage />
            </PrivateRoute>
          }
        />

        <Route path="/not-found-404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/not-found-404" />} />
      </Routes>
      <Footer />
    </div>
  ) : (
    <Container style={{ height: "100vh" }} spacing={3}>
      <Grid
        style={{ height: "100%" }}
        container
        spacing={3}
        alignItems="center"
        justifyContent="center"
      >
        <Grid style={{ paddingLeft: "0px", paddingTop: "0px" }} item>
          <CircularProgress color="inherit" />
        </Grid>
      </Grid>
    </Container>
  );
}

export default App;
