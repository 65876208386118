import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import QuestionsList from './QuestionsList.jsx';
import './main.sass';

function Main() {
    return (
        <section className="questions-wrapper">
            <div className="ask-question-button-wrapper ask-question-button-wrapper-top inner-padding">
              <Link to={'/ask'}>
                <button className="ask-question-button">Задать вопрос</button>
              </Link>
            </div>

            <QuestionsList/>
        </section>)
}

export default Main;