import * as yup from "yup";

yup.setLocale({
  mixed: {
    required: "Пожалуйста, заполните это поле",
  },
  string: {
    min: "Не может быть короче ${min} символов",
    email: "Неправильный формат почты",
  }
});

export default yup