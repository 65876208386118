import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import { useParams, Link } from "react-router-dom";
import api from "../../services/api";
import Info from "../common/Info/Info";
import "./user-page.sass";

function UserPage() {
  let { id } = useParams();
  const [activeTab, setTab] = useState("questions");
  const [data, setData] = useState(null);

  useEffect(() => {
    api.userProfile
      .profile(id)
      .then((response) => {
        setData(response);
      })
      .catch((e) => {});
  }, []);

  const handleTabClick = (tab) => {
    setTab(tab);
  };

  const handleFollowClick = (item) => {};

  const handleQuestionСomplaintClick = (item) => {
    //api.userProfile.complaint({})
  };

  const handleReplyThreadСomplaintClick = (item) => {
    //api.userProfile.complaint({})
  };

  return data ? (
    <div className="user-profile-wrapper">
      <div className="user-profile inner-padding">
        <div className="user-info-wrapper">
          {/*<img src={data.photo} alt="user-avatar" className="avatar" />*/}
          <svg
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiAvatar-fallback css-13y7ul3 avatar"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="PersonIcon"
            style={{ minWidth: "40px", minHeight: "40px" }}
          >
            <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
          </svg>
          <div className="user-info">
            <h2 className="user-name">{data.name}</h2>
            <span className="user-email">{data.email}</span>
          </div>
        </div>

        <ul className="user-activity-list">
          <li
            onClick={() => handleTabClick("questions")}
            className={`roboto user-activity-item${
              activeTab === "questions" ? " user-activity-item--active" : ""
            }`}
          >
            Вопросы
          </li>
          <li
            onClick={() => handleTabClick("reply_threads")}
            className={`roboto user-activity-item${
              activeTab === "reply_threads" ? " user-activity-item--active" : ""
            }`}
          >
            Ответы
          </li>
          {/*<li
            onClick={() => handleTabClick("subscriptions")}
            className={`user-activity-item${
              activeTab === "subscriptions" ? " user-activity-item--active" : ""
            }`}
          >
            Отслеживаемые
          </li>*/}
        </ul>
      </div>

      <ul className="user-activity__content-list">
        {activeTab === "reply_threads" ? (
          <Reply_threads
            onComplaint={handleReplyThreadСomplaintClick}
            data={data[activeTab]}
          ></Reply_threads>
        ) : (
          <Questions
            onComplaint={handleQuestionСomplaintClick}
            data={data[activeTab]}
          ></Questions>
        )}
      </ul>
    </div>
  ) : (
    <></>
  );
}

const Questions = ({ data, onComplaint }) => {
  return (
    <>
      {
        (() => {
          if (data.length) {
            return    data.map((item) => (
              <li key={item.id} className="user-activity user-question inner-padding">
                <div className="question-body">
                  <div className="question__meta">
                    <svg
                      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiAvatar-fallback css-13y7ul3 question__user-avatar"
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="PersonIcon"
                      style={{
                        minWidth: "40px",
                        minHeight: "40px",
                        maxWidth: "40px",
                        maxHeight: "40px",
                      }}
                    >
                      <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
                    </svg>
      
                    <span className="question__topic">
                      {item.user_name ? `${item.topic_name}, ` : item.topic_name}
                    </span>
                    <span className="question__author">{item.user_name}</span>
                    <span className="spacer"></span>
      
                    <Info type="question" id={item.id} />
                  </div>
                  <h3 className="question__title">
                    <Link to={`/question/${item.id}`} style={{ color: "#000" }}>
                      {item.header}
                    </Link>
                  </h3>
                </div>
                <a className="question__reply-link">
                  <IconButton className="question__reply-icon">
                    <ChatOutlinedIcon />
                  </IconButton>
                  <span>
                    <Link to={`/question/${item.id}`} style={{ color: "#000" }}>
                      Ответить
                    </Link>
                  </span>
                </a>
              </li>
            ))
          } else {
            return <p className="user-activity">Нет вопросов</p>
          }
        })()
   }
    </>
  );
};

const Reply_threads = ({ data, onComplaint }) => {
  return (
    <>
      {
      (()=>{
        if (data.length) {
          return data.map((item) => (
            <li key={item.id} className="user-activity user-question inner-padding">
              <div className="question-body">
                <div className="question__meta">
                  <svg
                    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiAvatar-fallback css-13y7ul3 question__user-avatar"
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="PersonIcon"
                    style={{
                      minWidth: "40px",
                      minHeight: "40px",
                      maxWidth: "40px",
                      maxHeight: "40px",
                    }}
                  >
                    <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
                  </svg>
                  <span className="question__topic">
                    {item.user_name ? `${item.topic_name}, ` : item.topic_name}
                  </span>
                  {/* <span className="question__author">{`Автор вопроса: ${item.user_name}`}</span> */}
    
                  <span className="spacer"></span>
    
                  <Info type="reply_thread" id={item.id} />
                </div>
                <div className="reply__content roboto">
                  <Link
                    to={`/question/${item.question_id}`}
                    style={{ color: "#000" }}
                  >
                    "{item.content}"
                  </Link>
                </div>
    
                <a className="question__reply-link">
                  <IconButton className="question__reply-icon">
                    <ChatOutlinedIcon />
                  </IconButton>
                  <span>
                    <Link
                      className="roboto comment"
                      to={`/question/${item.question_id}`}
                      style={{ color: "#000" }}
                    >
                      Комментировать
                    </Link>
                  </span>
                </a>
              </div>
            </li>
          ))
        } else {
          return <p className="user-activity">Нет ответов</p>
        }
      })()
    }
    </>
  );
};

export default UserPage;
