import React, { useState, useEffect } from "react";
import AnswerForm from "./AnswerForm";
import AnswersList from "./AnswersList";
import QuestionBody from "./QuestionBody";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import api from "../../services/api";
import { questionPage } from '../../store.js';
import { report } from '../../methods';
import './question-page.sass';

function QuestionPage() {
  let { id } = useParams();
  const [data, setData] = useState(null);

  const loadData = () => {
    api.question
      .get(id)
      .then((response) => {
        setData(response);
      })
      .catch((e) => {
      });
  };


  const handleCommentCreate = ({ thread_id, content }) => {
    api.question.createComment({ thread_id, content }).then((res) => {
      if (res) {
        questionPage.getData(id)
      }
    });
  };

  const handleAnswerCreate = ({ question_id, content }) => {
    api.question.createAnswer({ question_id, content }).then((res) => {
      if (res) {
        questionPage.getData(id)
      }
    });
  };


  useEffect(() => {
    questionPage.getData(id)
  }, []);
  return Object.keys(questionPage.data).length ? (
    <div className="question-page-wrapper">
      <div className="question-page">
        <div>
          <QuestionBody
            {...questionPage.data}
            report={report}
            handleAnswerClick={handleCommentCreate}
          />
          <AnswersList
            {...questionPage.data}
            report={report}
            handleCommentCreate={handleCommentCreate}
          />
        </div>
        <AnswerForm onAnswerSubmit={(value) =>handleAnswerCreate({question_id: id, content: value})} />
      </div>
    </div>
  ) : (
    <></>
  );
}

export default observer(QuestionPage);
