import auth from "./endpoints/auth";
import userProfile from "./endpoints/userProfile";
import question from "./endpoints/question";
import questions from "./endpoints/questions";
import load from "./endpoints/load";
import report from "./endpoints/report";
import categories from "./endpoints/categories";
import subscribe from "./endpoints/subscribe";
import ask from "./endpoints/ask";
import searchQuestion from "./endpoints/searchQuestion";
import searchTopic from "./endpoints/searchTopic";
import defineAnswer from "./endpoints/defineAnswer";

const allEndpoints = {
  auth,
  userProfile,
  question,
  questions,
  load,
  report,
  subscribe,
  categories,
  ask,
  searchQuestion,
  searchTopic,
  defineAnswer,
};

export default allEndpoints;
