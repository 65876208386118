import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Info from "../common/Info/Info";
import { questionPage } from "../../store.js";
import { observer } from "mobx-react-lite";
import { useParams, Link } from "react-router-dom";
function QuestionBody({ header, content, user_id, report }) {
  let { id } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="question inner-padding">
      <div className="question-body">
        <div className="question__meta">
          <svg
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiAvatar-fallback css-13y7ul3 avatar"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="PersonIcon"
            style={{ minWidth: "40px", minHeight: "40px" }}
          >
            <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
          </svg>

          {/*<span className="question__topic">{question_category}, </span>*/}
          {/*<span className="question__author">{author_name}</span>*/}
          <h5 className="question__author">
            {user_id.name}
          </h5>
          <span className="spacer"></span>
          <Info type="question" id={id} />
        </div>
        <h3 className="question__title">{header}</h3>
        <p className="question__description">{content}</p>
      </div>
    </div>
  );
}

export default observer(QuestionBody);
