import React, {useState, useEffect}  from "react";
import IconButton from "@mui/material/IconButton";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { report } from '../../../methods';

function Info(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="question__options">
    <IconButton>
  <MoreVertOutlinedIcon onClick={handleClick} />
</IconButton>
<Menu
  id={`options-${props.id}`}
  anchorEl={anchorEl}
  open={open}
  onClose={handleClose}
  MenuListProps={{
    "aria-labelledby": "basic-button"
  }}
>
  <MenuItem onClick={()=>report({type:props.type, object_id: props.id, content: ''})} className="roboto">Пожаловаться</MenuItem>
</Menu>
</div>
  );
}

export default Info;
