import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InputBase from "@mui/material/InputBase";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { observer } from "mobx-react-lite";
import { search } from "../../store.js";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useAuth from "../../hooks/useAuth";
import './header.sass';

function Header() {
  const auth = useAuth();

  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorTopics, setAnchorTopics] = useState(null);

  const navigate = useNavigate();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenTopics = (event) => {
    setAnchorTopics(event.currentTarget);
  };

  const handleClickMenu = (url) => {
    navigate(url);
  };
  
  const handleClickTopics = (url) => {
    navigate(url);
  };

  const settings = [
    {
      text: "Профиль",
      action: () => {
        handleClickMenu("/user/" + auth.user.id);
        setAnchorElUser(null);
      },
    },
    {
      text: "Выйти",
      action: () => {
        auth.logOut();
        setAnchorElUser(null);
      },
    },
  ];

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseTopics = () => {
    setAnchorTopics(null);
  };

  const find = () => {
    search.run();
    navigate("/");
  };
  useEffect(() => {
    if (auth.token) {
      search.getTopics()
    }
}, [auth])
  return (
    <header>
      <div className="header">
      <a href="/">
        <h1 className="site-logo">ФОРУМ</h1>
      </a>

      <div className="search-bar">
        <IconButton className="search-icon" onClick={() => find()}>
          <SearchSharpIcon />
        </IconButton>
        <InputBase
          id="outlined-basic"
          placeholder="Поиск..."
          value={search.query}
          onChange={(e) => search.write(e.target.value)}
          onKeyPress={(e) => (e.code === "Enter" ? find() : "")}
        />
      </div>
      <div className={`select-category desktop show-${auth.token ? 'block' : 'none'}`}>
                <span className="select-category-btn roboto" onClick={(e) => handleOpenTopics(e)}>{search.topic ? `Категория: ${search.topic}` : 'Искать по категории'}</span>
                {/* {search.selectCategory ? `Категория: ${search.selectCategory}` : 'Искать категорию'} */}
                <Menu
              sx={{ mt: "25px", ml: '52px' }}
              id="menu-appbar"
              anchorEl={anchorTopics}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={Boolean(anchorTopics)}
              onClose={handleCloseTopics}
            >
              {search.topics.map((topic, id) => (
                <MenuItem key={`topic-${id}`}  onClick={() => {
                  search.findTopicByName(topic)
                  handleCloseTopics()
                  navigate('/')
                  }} >
                  <Typography textAlign="center">{topic}</Typography>
                </MenuItem>
              ))}
            </Menu>
        </div>
      <span className="spacer"></span>

      <a href="https://map.ncpti.ru/" className="map-link desktop roboto">
        Интерактивная карта
      </a>

      {auth.token ? (
        <>
          <div>
            <IconButton onClick={handleOpenUserMenu}>
              <PersonOutlineIcon className="header-icon" />
            </IconButton>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.text} onClick={setting.action}>
                  <Typography textAlign="center">{setting.text}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </div>
          <div>
            <IconButton>
              <NotificationsNoneOutlinedIcon className="header-icon" />
            </IconButton>
          </div>
        </>
      ) : (
        <></>
      )}
      </div>
      <div className="header mobile-flex">
      <div className={`select-category mobile`}>
                <span className="select-category-btn" onClick={() => search.toggleSelectTopics()}>{search.topic ? `Категория: ${search.topic}` : 'Искать по категории'}</span>
                {/* {search.selectCategory ? `Категория: ${search.selectCategory}` : 'Искать категорию'} */}
                <div className={`select-category-dropdown show-${search.showSelectTopics ? 'flex' : 'none'}`}>
                   {(() => {
                       return search.topics.map((topic, i) => {
                           return <span key={`topic-${i}`}
                           onClick={() => {
                              search.findTopicByName(topic)
                              navigate('/')
                            }} 
                           className="select-category-dropdown-item" >{topic}</span>
                        }
                        ) 
                    })()} 
                </div>
        </div>
      <span className="spacer"></span>
        <a href="https://map.ncpti.ru/" className="map-link mobile">
        Интерактивная карта
      </a>
      </div>
    </header>
  );
}

export default observer(Header);
