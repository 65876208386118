import api from './services/api'

export const report = (type: any, object_id: string, content: string) => {
  api.report.action({
    entity: type.type,
    entity_id: type.object_id
  })
}

export const subscribe = (type: string, object_id: string, content: string) => {

  api.subscribe.action({
    entity: type,
    entity_id: object_id
  })
}

export const getUserName = (id: any) => {
  return api.userProfile.profile(id)
    .then((response: any) => {
      return response.name
    })
  }