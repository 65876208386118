import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import { observer } from "mobx-react-lite";
import { questions, search, moreBtn } from "../../store.js";
import { Link } from "react-router-dom";
import Info from "../common/Info/Info";

function QuestionsList() {
  const closeOptions = () => {
    if (
      questions.optionId !== undefined &&
      questions.list[questions.optionId] !== undefined
    ) {
      questions.showOptions(questions.list[questions.optionId], "");
    }
  };
  useEffect(() => {
    if (questions.list.length == 0) {
      questions.setList();
    }
    if (Object.keys(questions.topics).length == 0) {
      questions.getTopics();
    }
    window.addEventListener("mouseup", closeOptions);
    return () => {
      window.removeEventListener("mouseup", closeOptions);
    };
  }, []);
  return (
    <ul className="questions">
      {(() => {
        if (questions.list.length > 0) {
          return (
            <>
              {(() => {
                return questions.list.map((question, i) => {
                  return (
                    <div key={`question-${i}`}>
                      <div className="question">
                        <div className="question-body">
                          <div className="question__meta">
                            <svg
                              className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiAvatar-fallback css-13y7ul3 avatar"
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="PersonIcon"
                              style={{ minWidth: "40px", minHeight: "40px" }}
                            >
                              <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
                            </svg>
                            <span
                              className="question__topic"
                              onClick={() =>
                                search.findTopicById(question.topic_id)
                              }
                            >
                              {questions.displayTopicName(question.topic_id)},{" "}
                            </span>
                            <span className="question__author">
                              <Link
                                to={`user/${question.user_id.id}`}
                                style={{ color: "#9698A0" }}
                              >
                                {question.user_id.name}
                              </Link>
                            </span>

                            <span className="spacer"></span>
                            <Info type="question" id={question.id} />
                          </div>
                        </div>
                        <h3 className="question__title">
                          <Link
                            to={`question/${question.id}`}
                            style={{ color: "black" }}
                          >
                            {question.header}
                          </Link>
                        </h3>

                        <Link
                          to={`question/${question.id}`}
                          className="question__reply-link"
                        >
                          <ChatOutlinedIcon />
                          <span>Ответить</span>
                        </Link>
                      </div>
                    </div>
                  );
                });
              })()}

              <div
                className={`show-more-questions-button-wrapper show-${
                  moreBtn.state ? "flex" : "none"
                }`}
              >
                <div className="ask-question-button-wrapper ask-question-button-wrapper-bottom">
                  <button
                    className="show-more-questions-button"
                    onClick={() => questions.loadMore()}
                  >
                    {questions.loading ? "Загрузка..." : "Показать еще"}
                  </button>
                </div>
              </div>
            </>
          );
        } else {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "20px",
              }}
            >
              Вопросов пока нет...
            </div>
          );
        }
      })()}
    </ul>
  );
}

export default observer(QuestionsList);
