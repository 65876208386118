import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validation";
import api from "../../services/api";
import useAuth from "../../hooks/useAuth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import './login.sass';

function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const auth = useAuth();
  let navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    setIsLoading(true);

    const requestData = {
      username: data.email,
      password: data.password,
    };

    api.auth
      .login(requestData)
      .then((response) => {
        if (response.id) {
          auth.setUser({
            email: response.email.trim(),
            id: response.id,
          });
          auth.setToken(response.jwt);
          navigate("/");
          setError(false);
          localStorage.setItem("token", response.jwt);
          localStorage.setItem("userId", response.id);
        }
        if (response.status === 401) {
          setError(true);
        }
      })
      .catch((e) => {
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="login-wrapper">
      <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="login-form__title">Авторизация</h2>
        {error ? (
          <span
            style={{
              color: "#d32f2f",
              padding: ".4em .6em",
              width: "100%",
              textAlign: "center",
              display: "inline-block",
            }}
          >
            Неправильная почта или пароль
          </span>
        ) : (
          <></>
        )}
        <div className="login-form__inputs">
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                id="outlined-basic"
                className="login-form__input"
                placeholder="Почта"
              />
            )}
          />
          <span
            style={
              errors.email ? { color: "#d32f2f", padding: ".4em .6em" } : {}
            }
          >
            {errors.email ? errors.email.message : ""}
          </span>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                id="outlined-basic"
                className="login-form__input"
                style={{ marginTop: "2rem" }}
                placeholder="Пароль"
                type="password"
              />
            )}
          />
          <span
            style={
              errors.password ? { color: "#d32f2f", padding: ".4em .6em" } : {}
            }
          >
            {errors.password ? errors.password.message : ""}
          </span>
        </div>

        <button
          type="submit"
          disabled={isLoading}
          className="login-form__enter-button"
        >
          Войти
        </button>
      </form>
    </div>
  );
}

export default Login;
