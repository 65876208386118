const baseURL = '';

class Request {
  auth = () => {
    const token = localStorage.getItem("token");
    if (token) {
      return { Authorization: "Bearer " + token };
    }
    return;
  };

  post = async (url, data) => {
    const requestData = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...this.auth(),
      },
      body: JSON.stringify(data),
    };
    try {
      const res = await fetch(baseURL + url, requestData);
      if (res.status == 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        if (window.location == '/login')
          window.location = '/login'
        throw res;
      }
      const data = await res.json();
      return data
    } catch (error) {
      console.error(error);
      throw error
    }
  };

  get = async (url) => {
    try {
      const res = await fetch(baseURL + url, { headers: this.auth() });
      if (res.status == 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        if (window.location == '/login')
          window.location = '/login'
        throw res;
      }
      const data = await res.json();
      return data
    } catch (error) {
      console.error(error);
      throw error
    }
  };

  put = async () => {};

  delete = async () => {};
}

const RequestInstance = new Request();

export default RequestInstance;
