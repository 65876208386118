import React, { useState } from "react";
import TextField from "@mui/material/TextField";

function AnswerForm({ onAnswerSubmit }) {
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);

  const onClick = () => {
    if (value) {
      onAnswerSubmit(value);
      setValue("");
      setError(false)
    } else {
      setError(true);
    }
  };

  return (
    <div className="answer-form inner-padding">
      <h3 className="answer-form__title">Ответить на вопрос</h3>
      <div className="answer-form__body">
        <div className="answer-form__info">
          <span className="answer-form-reply-label roboto">Введите текст ответа</span>
        </div>

        <TextField
          className="answer-form__input"
          multiline
          minRows={6}
          maxRows={6}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
        {error ? <span className="error">Пожалуйста, заполните это поле</span> : <></>}
      </div>
      <div className="answer-form__send-button-wrapper">
        <button
          onClick={onClick}
          className="answer-form__send-button"
        >
          Ответить
        </button>
      </div>
    </div>
  );
}

export default AnswerForm;
