import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validation";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ask, topics, search } from "../../../store.js";
import { observer } from "mobx-react-lite";
import "./index.sass";
import { Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
function CreateQuestion() {
  const [isLoading, setIsLoading] = useState(false);
  const handleCloseTopics = () => {
    setAnchorTopics(null);
  };
  const [title, setTitle] = useState('')
  const [titleError, setTitleError] = useState(false)
  const [description, setDescription] = useState('')
  const [descriptionError, setDescriptionError] = useState(false)
  const [topicError, setTopicError] = useState(false)
  const [topic, setTopic] = useState({id:'', content:''})
  const navigate = useNavigate();
  const [anchorTopics, setAnchorTopics] = useState(null);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const handleOpenTopics = (event:any) => {
    setAnchorTopics(event.currentTarget);
  };
  const onSubmit = () => {
    if (topic.content.length == 0) {
      setTopicError(true)
    } else {
      setTopicError(false)
    }
    if (title.length == 0) {
      setTitleError(true)
    } else {
      setTitleError(false)
    }
    if (description.length == 0) {
      setDescriptionError(true)
    } else {
      setDescriptionError(false)
    }
    if (topic.content.length != 0 && title.length != 0 && description.length != 0) {
      const data = {
        category_id:topic.id,
        title:title,
        description:description
      }
      setIsLoading(true)
      ask.create(data).then(() => {
        navigate("/")
        setIsLoading(false)
      }).catch((e) => {
        console.log(e)
      })
    } 
  };

  return (
    <div className="create-question-wrapper">
      <div
        className="create-question inner-padding"
      >
        <h2 className="create-question__title">Задать вопрос</h2>

        <div className="create-question__input-wrapper">
          <label className="create-question__input-label">
            <span className={`roboto ${titleError?'create-question__error':''}`}>Вопрос*</span>
          </label>
      
              <TextField
                className={`create-question__input`}
                multiline
                maxRows={3}
                onChange={(e)=>setTitle(e.target.value)}
              />
     
          {/* <span className="error">{errors.title ? errors.title.message : ""}</span> */}
        </div>

        <div className="create-question__input-wrapper">
          <label className="create-question__input-label">
            <span className={`roboto ${descriptionError?'create-question__error':''}`}>Описание*</span>
          </label>
     
              <TextField
                className={`create-question__input ${descriptionError?'create-question__error':''}`}
                multiline
                minRows={4}
                maxRows={6}
                onChange={(e)=>setDescription(e.target.value)}
              />
     
          {/* <span className="error">{errors.description ? errors.description.message : ""}</span> */}
        </div>

        
        <div className="create-question__input-wrapper">
          <label className={`create-question__input-label roboto ${topicError?'create-question__error':''}`}>
            Категория*
          </label>
          <div className={`create-question__topics roboto`} onClick={(e) => handleOpenTopics(e)}>{topic.content ? topic.content : 'Выбрать из списка'}</div>
                <Menu
              sx={{ mt: "25px", ml: '52px' }}
              id="menu-appbar"
              anchorEl={anchorTopics}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={Boolean(anchorTopics)}
              onClose={handleCloseTopics}
            >
              {search.topicsForCreate.map((topic:any, id:any) => (
                <MenuItem key={`topic-${id}`}  onClick={() => {
                  setTopic(topic)
                  setTopicError(false)
                  handleCloseTopics()
                  }} >
                  <Typography textAlign="center">{topic.content}</Typography>
                </MenuItem>
              ))}
            </Menu>
          {/* <Controller
            name="category_id"
            control={control}
            render={({ field }) => (
              <FormControl
                className="create-question__input-selector"
                sx={{ minWidth: 200 }}
                size="small"
              >
                <Select {...field}>
                  {(() => {
                    if (search.topics.length) {
                      console.log(search.topics)
                      console.log('topics.list')
                      return topics.list.map((category: any) => (
                        <MenuItem value={category.id}>
                          {category.content}
                        </MenuItem>
                      ));
                    }
                  })()}
                </Select>
              </FormControl>
            )}
          /> */}
          {/* <span className="error">{topicError ? 'Пожалуйста, укажите категорию' : ""}</span> */}
        </div>

        <button
          className="create-question__submit-button"
          type="submit"
          disabled={isLoading}
          onClick={()=>onSubmit()}
        >
          Опубликовать вопрос
        </button>
      </div>
    </div>
  );
}

export default observer(CreateQuestion);
