import React from "react";
import { makeAutoObservable } from "mobx";
import api from "./services/api";
import { Thermostat } from "@mui/icons-material";
import { getUserName } from "./methods";

class QuestionPage {
  users = {};
  data = {};
  constructor() {
    makeAutoObservable(this);
  }

  getData(id) {
    api.question.get(id).then((response) => {
      this.data = response;
    });
  }

  addUser(id) {
    if (!Object.keys(this.users).includes(id)) {
      getUserName(id).then((res) => {
        this.users[id] = res;
      });
    }
  }
}

class MoreBtn {
  state = true;
  constructor() {
    makeAutoObservable(this);
  }
  setState(value) {
    this.state = value;
  }
}

class Questions {
  loading = false;
  list = [];
  page = 0;
  topics = {};
  users = {};
  constructor() {
    makeAutoObservable(this);
  }

  getTopics() {
    api.categories.list().then((response) => {
      response.map((topic) => {
        this.topics[topic.id] = topic.content;
      });
    });
  }

  displayTopicName(id) {
    return this.topics[id];
  }

  setList() {
    api.questions.list().then((response) => {
      if (response.content.length < 10) {
        moreBtn.setState(false);
      } else {
        moreBtn.setState(true);
      }
      this.list = response.content;
    });
  }

  updateList(value) {
    this.list = this.list.concat(value);
  }

  clearList() {
    this.list = [];
  }

  loadMore() {
    if (!this.loading) {
      this.loading = true;
      this.page = this.page + 1;
      api.load.next(this.page).then((response) => {
        this.loading = false;
        this.list = this.list.concat(response.content);
        if (response.content.length < 10) {
          moreBtn.setState(false);
        } else {
          moreBtn.setState(true);
        }
      });
    }
  }
}

class Search {
  query = "";
  result = [];
  loading = false;
  topics = [];
  showSelectTopics = false;
  topic = "";
  topicsForCreate = [];
  constructor() {
    makeAutoObservable(this);
  }

  getTopics() {
    api.categories.list().then((response) => {
      this.topics = response.map((topic, id) => {
        this.topicsForCreate[id] = {};
        this.topicsForCreate[id].id = topic.id;
        this.topicsForCreate[id].content = topic.content;
        return topic.content;
      });
    });
  }

  toggleSelectTopics() {
    this.showSelectTopics = !this.showSelectTopics;
  }

  write(value) {
    this.query = value;
  }

  findTopicByName(name) {
    this.query = "";
    this.showSelectTopics = false;
    this.topic = name;
    api.searchTopic.topic(name).then((response) => {
      moreBtn.setState(false);
      if (response.length > 1) {
        questions.list = [].concat(...response.map((e) => e.questions));
      } else if (response.length == 1) {
        questions.list = response[0].questions;
      }
    });
  }

  findTopicById(id) {
    this.query = "";
    this.topic = questions.displayTopicName(id);
    api.searchTopic.topic(questions.displayTopicName(id)).then((response) => {
      moreBtn.setState(false);
      if (response.length > 1) {
        questions.list = [].concat(...response.map((e) => e.questions));
      } else if (response.length == 1) {
        questions.list = response[0].questions;
      }
    });
  }
  run() {
    let t = { id: '' };

    if (this.topic) {
      t = this.topicsForCreate.find((i) => i.content === this.topic);
    }

    api.searchQuestion.question(this.query, t.id).then((response) => {
      if (this.query.length) {
        moreBtn.setState(false);
      } else {
        moreBtn.setState(true);
      }
      questions.list = response.content;
    });
  }
}

class Ask {
  title = "";
  description = "";
  categories = [];
  category_id = 1;
  constructor() {
    makeAutoObservable(this);
    api.categories.list().then((response) => {
      this.categories = response;
    });
  }

  setCategory(value) {
    this.category_id = value;
  }

  write(field, value) {
    switch (field) {
      case "title":
        this.title = value;
        break;
      case "description":
        this.description = value;
        break;
    }
  }

  create({ title, description, category_id }) {
    return api.ask
      .question({
        header: title,
        content: description,
        topic_id: category_id,
      })
      .then(() => {
        questions.setList();
        this.title = "";
        this.description = "";
      });
  }
}

class Topics {
  list = [];
  constructor() {
    makeAutoObservable(this);
  }
  get() {
    api.categories.list().then((response) => {
      response.map((topic) => {
        this.list[topic.id] = topic.content;
      });
    });
  }
}
export const questions = new Questions();
export const search = new Search();
export const ask = new Ask();
export const moreBtn = new MoreBtn();
export const questionPage = new QuestionPage();
export const topics = new Topics();
